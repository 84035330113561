<script>
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor";
import Autoformat from "@ckeditor/ckeditor5-autoformat/src/autoformat";
import Fontcolor from "@ckeditor/ckeditor5-font/src/fontcolor";
import Fontsize from "@ckeditor/ckeditor5-font/src/fontsize";
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily";
import Link from "@ckeditor/ckeditor5-link/src/link";
import List from "@ckeditor/ckeditor5-list/src/list";
import Todolist from "@ckeditor/ckeditor5-list/src/todolist";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";

import Swal from "sweetalert2";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import axios from "axios";

export default {
  page: {
    title: "關於我們管理",
    meta: [{ name: "description", content: appConfig.description }],
  },
  data() {
    return {
      title: "關於我們管理",
      items: [
        {
          text: "頁面管理",
          href: "/",
        },
        {
          text: "關於我們",
          active: true,
        },
      ],
      editor: ClassicEditor,
      editorConfig: {
        plugins: [
          Essentials,
          Autoformat,
          FontBackgroundColor,
          Fontcolor,
          Fontsize,
          FontFamily,
          Bold,
          Italic,
          Underline,
          Strikethrough,
          Superscript,
          Subscript,
          BlockQuote,
          Heading,
          SourceEditing,
          Link,
          List,
          Todolist,
          Paragraph,
          Alignment,
          Indent,
          CodeBlock,
          Code,
        ],

        toolbar: {
          items: [
            "heading",
            "|",
            "alignment",
            "|",
            "bold",
            "italic",
            "link",
            "|",
            "blockQuote",
            "numberedList",
            "bulletedList",
            "sourceEditing",
            "undo",
            "redo",
          ],
        },
      },

      // page data
      data: {},
    };
  },
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
  },
  methods: {
    get_data() {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}copy?p_t=2`)
        .then((result) => {
          if (result.data.status == 0) {
            return (this.result_msg = result.data.msg);
          }
          this.data = result.data.data;
        })
        .catch((error) => {
          this.result_msg = error.response.data.msg;
        });
    },

    update_data() {
      axios
        .post(
          `${process.env.VUE_APP_BACKEND_URL}copy/${this.data.id}`,
          this.data
        )
        .then((result) => {
          if (result.data.status == 0) {
            Swal.fire("失敗", result.data.msg, "error");
            return;
          }
          Swal.fire("成功!", "", "success");
        })
        .catch((error) => {
          Swal.fire("失敗", error.response.data.msg, "error");
          console.log(error.response.data.errors);
        });
    },
  },
  beforeMount() {
    this.get_data();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12" v-if="1 <= data.open_section">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0">區塊 1</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <div class="ckeditor-classic">
              <ckeditor
                v-model="data.section1"
                :editor="editor"
                :config="editorConfig"
              ></ckeditor>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-lg-12" v-if="2 <= data.open_section">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0">區塊 2</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <div class="ckeditor-classic">
              <ckeditor
                v-model="data.section2"
                :editor="editor"
                :config="editorConfig"
              ></ckeditor>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-lg-12" v-if="3 <= data.open_section">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0">區塊 3</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <div class="ckeditor-classic">
              <ckeditor
                v-model="data.section3"
                :editor="editor"
                :config="editorConfig"
              ></ckeditor>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-lg-12" v-if="4 <= data.open_section">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0">區塊 4</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <div class="ckeditor-classic">
              <ckeditor
                v-model="data.section4"
                :editor="editor"
                :config="editorConfig"
              ></ckeditor>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-lg-12" v-if="5 <= data.open_section">
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0">區塊 5</h4>
          </div>
          <!-- end card header -->
          <div class="card-body">
            <div class="ckeditor-classic">
              <ckeditor
                v-model="data.section5"
                :editor="editor"
                :config="editorConfig"
              ></ckeditor>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->

      <div class="col-lg-12 mb-3 d-flex justify-content-end">
        <button class="btn btn-primary m-1" @click="update_data">更新</button>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
